import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

const ArrowDown = ({ scrollContainerId }) => { 
  const [isScrollable, setIsScrollable] = useState(false);
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
  useEffect(() => {
    const scrollContainer = document.getElementById(scrollContainerId);
    let resizeObserver;

    if (scrollContainer) {
        // Function to update isScrollable
        const checkScrollability = () => {
            setIsScrollable(scrollContainer.scrollHeight > scrollContainer.clientHeight);
        };

        // Create and observe with Resize Observer
        resizeObserver = new ResizeObserver(checkScrollability);
        resizeObserver.observe(scrollContainer);

        // Initial check
        checkScrollability();

        // Add scroll event listener
        scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
        if (scrollContainer) {
            scrollContainer.removeEventListener("scroll", handleScroll);
        }
        if (resizeObserver) {
            resizeObserver.disconnect();
        }
    };
}, [scrollContainerId]);

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setHasScrolledToBottom(true);
    } else {
      setHasScrolledToBottom(false);
    }
  };

  useEffect(() => {
    const scrollContainer = document.getElementById(scrollContainerId);
    if (scrollContainer) {
      setIsScrollable(scrollContainer.scrollHeight > scrollContainer.clientHeight);
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollContainerId]); 

  return (
     <div
      className={` z-9 absolute transition-opacity ease-in duration-300 bg-white h-fit rounded-full px-2 
      ${
        isScrollable && !hasScrolledToBottom ? "opacity-100" : "opacity-0"}   z-9 rounded-full right-1/2 center-0 bottom-4`}
    >
        <FontAwesomeIcon icon={faArrowDown} size="lg" fade style={{ color: "#7777777" }} />
    </div>
  );
};

export default ArrowDown;
