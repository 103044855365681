import React from "react";
import arrow from "../assets/images/angles-left-solid.png";

const Back = ({ onClick }) => {

  return (
        <button onClick={onClick}
          className="flex font-helvitica xxs:pl-5 lg:pl-10  font-bold text-white hover:underline h-[10vh] w-fit  justify-between items-center"
        >
          <img
            alt=""
            src={arrow}
            className=" xxs:w-[30px] lg:w-[35px]"
          />
        </button>
  );
};

export default Back;
