import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import { motion } from "framer-motion";
import Language from "../../components/Language";
import SearchBar from "../../components/SearchBar";
import logo from "../../assets/logo/logo.png";
import NavbarContext from "./NavbarContext";
import { useNavigate } from "react-router-dom";
import useMappedLanguage from "../../components/UseMappedLanguage";
import axios from "axios";

function NavBar() {
  const [navbar, setNavbar] = useState(false);
  const { navbarData, setNavbarData } = useContext(NavbarContext);
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef(null);
  const mappedLanguage = useMappedLanguage();
  const navigate = useNavigate();
  const languageRef = useRef(null);

  const handleCategoryClick = (event, category) => {
    setIsOpen(!isOpen);
    navigate(`/category/${category.wikiCategoryID}`);
  };

  const categoryColors = useMemo(
    () => ({
      yellow: "#366B75",
      blue: "#165881",
      green: "#2D604D",
      brown: "#32444B",
    }),
    []
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const menuUrl = `https://www.linked.farm/api/v2/wiki/view/wiki.php?data=wikiCategories&api=wefg496ad45afsjeg4s633924ujimshd4g56a4f13aghg56f4sa&lang=${mappedLanguage}`;
        const menuResponse = await axios.get(menuUrl);
        if (menuResponse) {
          const categoriesArray = Object.values(menuResponse.data);

          const menuWithColors = categoriesArray.map((category, index) => {
            const colorIndex = index % Object.keys(categoryColors).length;
            const colorKey = Object.keys(categoryColors)[colorIndex];
            return { ...category, color: categoryColors[colorKey] };
          });
          setNavbarData(menuWithColors);
          console.log(menuWithColors);
        } else {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.error(
          "Erreur globale lors de la récupération des catégories:",
          error
        );
      }
    }

    fetchData();
  }, [setNavbarData, mappedLanguage, categoryColors]);

  const toggleNavbar = () => {
    setNavbar((prevNavbar) => !prevNavbar); 
  };

  const toggleJoinUs = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen); 
  };

  useEffect(() => {
    function handleClickOutsideNavbar(event) {
      if (
        navbarRef.current &&
        !navbarRef.current.contains(event.target) &&
        navbar
      ) {
        setNavbar(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutsideNavbar);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideNavbar);
    };
  }, [navbar]);

  useEffect(() => {
    function handleClickOutsideLanguage(event) {
      if (languageRef.current && !languageRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutsideLanguage);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideLanguage);
    };
  }, []);

  return (
    <nav
      className="  xxs:w-full bg-white fixed z-[11] h-[10vh] tracking-wider top-0  font-lexendbold
             lg:text-[15px] xl:text-[17px] xxl:text-[20px]  xxs:text-s  md:text-s flex justify-center"
      ref={navbarRef}
    >
      <div className="max-w-[1535px] justify-between relative flex w-full lg:pr-0 lg:pl-10 xxs:px-5 ">
        <div className="xxs:w-fit  h-full  lg:w-2/5 max-w-[1535px] justify-between items-center flex box-border  ">
          <motion.div
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <Link to="/" className="flex no-underline items-center ">
              <img
                src={logo}
                alt=""
                className=" xxs:w-[150px] xs:w-[200px]  s:w-[220px] xxs:h-fit lg:h-[6vh] lg:w-fit "
              />
            </Link>
          </motion.div>
        </div>

        <button
          className=" xxs:absolute xxs:right-5 xxs:top-[3vh] flex lg:right-10 lg:hidden rounded-md outline-none focus:light-green "
          onClick={toggleNavbar}
        >
          {navbar ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-[5vh] h-[5vh]"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-[5vh] h-[5vh]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </button>

        <div
          className={`xxs:w-fit lg:w-3/5 ${
            navbar ? "block" : "lg:flex hidden w-full"
          }`}
        >
          <div className="xxs:w-full border-t-white xxs:absolute xxs:justify-between h-[10vh] lg:static xxs:mt-[10vh] lg:mt-[0vh] box-border lg:pr-10 xxs:px-5  lg:w-full bg-white flex right-0 items-center lg:justify-between   ">
            <SearchBar />
            <div className="flex justify-between items-center xxs:w-fit  lg:w-2/5 h-[10vh] gap-2 relative">
              <div className=" px-5 ">
                <Language />
              </div>
              <button
                animate={isOpen ? "open" : "closed"}
                onClick={toggleJoinUs}
              >
                {isOpen ? (
      
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-[4vh] h-[4vh]"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <Menu />
                )}
              </button>
            </div>
            {isOpen && (
              <motion.ul
                ref={languageRef}
                variants={{
                  open: {
                    backgroundColor: "white",
                    color: "black",
                    transition: {
                      type: "spring",
                      bounce: 0,
                      duration: 0.1,
                      delayChildren: 0.1,
                      staggerChildren: 0.02,
                    },
                  },
                  closed: {
                    backgroundColor: "white",
                    boxShadow: "none",
                    border: "none",
                    transition: { duration: 0 },
                  },
                }}
                style={{ pointerEvents: isOpen ? "auto" : "none" }}
                className="languages xxs:h-fit lg:h-[7vh] xxs:flex-col lg:flex-row flex bg-white justify-evenly absolute text-black xxs:w-full xxs:px-0 lg:px-10 xxs:py-2 lg:py-2 text-center xxs:top-[10vh] lg:top-[10vh] xxs:right-0  lg:right-[0px] "
              >
                {Array.isArray(navbarData) &&
                  navbarData.map((category) => {
                    return (
                      <li key={category.wikiCategoryID}>
                        <div
                          className="uppercase cursor-pointer py-2 xxs:text-xxs lg:text-sm"
                          onClick={(e) => handleCategoryClick(e, category)}
                        >
                          {category.wikiCategoryName}
                        </div>
                      </li>
                    );
                  })}
              </motion.ul>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
