import { HashRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./Pages/Navbar/NavBar";
import HomePage from "./Pages/HomePage";
import CategoryPage from "./Pages/CategoryPage";
import SubCategoryPage from "./Pages/subCategoryPage/SubCategoryPage";
import { I18nextProvider } from "react-i18next";
import React from "react";
import i18n from "./translationServices/i18n";
import ScrollToTopOnNavigation from "./FunctionScroll";
import SearchPage from "./Pages/SearchPage";
import { NavbarProvider } from "./Pages/Navbar/NavbarProvider";

function App() {

  

  return (
    <NavbarProvider>
      <I18nextProvider i18n={i18n}>
        <Router>
          <ScrollToTopOnNavigation/>
          <NavBar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/:currentLanguage" element={<HomePage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/:currentLanguage/search" element={<SearchPage />} />
            <Route path="/:currentLanguage/page/:questionId/search" element={<SearchPage />} />
            <Route path="/page/:questionId/search" element={<SearchPage />} />
            <Route path="/category/:categoryID" element={<CategoryPage />} />
            <Route path="/page/:questionId/:currentLanguage" element={<SubCategoryPage />} />
            <Route path="/page/:questionId" element={<SubCategoryPage />} />
            <Route path="/subcategory/:categoryID" element={<SubCategoryPage />}/>
          </Routes>
        </Router>
      </I18nextProvider>
    </NavbarProvider>
  );
}

export default App;
