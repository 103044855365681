import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser,faTractor,faPeopleRoof,faQuestion,faBasketShopping,faCarrot,faBoxesStacked,faPiggyBank,faBuilding,faChartSimple,faPeopleArrows,faGears,faCheck} from '@fortawesome/free-solid-svg-icons';
const iconMap = {
  faUser,
  faTractor,
  faPeopleRoof,
  faQuestion,
  faBasketShopping,
  faCarrot,
  faBoxesStacked,
  faPiggyBank,
  faBuilding,
  faChartSimple,
  faPeopleArrows,
  faGears,
  faCheck
};

const Squarebtn = ({ onClick, name, children, isOpen, style, icon }) => {
  function extractIconName(iconString) {
    if (!iconString) {
      return null; // Retourne null si iconString est undefined ou null
    }
    const match = iconString.match(/icon=\{(fa[a-zA-Z]+)\}/);
    return match ? match[1] : null;
  }
  
  const iconName = extractIconName(icon);
  const faIcon = iconMap[iconName];

  return (
    <button
      type="button"
      className={`xxs:flex-row  lg:flex-col rounded-xl font-lexendbold p-3 flex xxs:justify-center lg:justify-center items-center  transition-[backdrop-filter]
        border hover:border-2 xxs:m-2 lg:m-0 xxs:h-fit lg:h-[220px] 
        xxs:w-[260px] lg:w-[220px] xxs:text-[14px] `}
      onClick={onClick}
      style={style}
      
    >      {faIcon && <FontAwesomeIcon icon={faIcon} className="xxs:hidden lg:block lg:h-[30px]" />}

      
      <div className="flex flex-col items-center">
        <p className={`uppercase  xxs:pt-0 lg:pt-3`}>
          {name}
        </p>
         <div className="sub-sub-categories">{children}</div>
      </div>
    </button>
  );
};

export default Squarebtn;
