import React from "react"; 
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Squarebtn from "../components/Squarebtn";

function SubCategoriesList({ category, style }) {
  const navigate = useNavigate();
  

  const handleSubCategoryClick = async (subCategory) => {
    if (!subCategory.subCategories) {
      navigate(`/subCategory/${subCategory.wikiCategoryID}/`);
    }
  };

  const itemVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 24 },
    },
    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
  };

  return (
    <div className="flex w-full xxs:flex-col s:flex-wrap s:flex-row justify-center items-center s:gap-5 lg:gap-5">
      {category.subCategories &&
        Object.values(category.subCategories).map((subCategory, index) => (
          <div key={index}>
            <Squarebtn
              name={subCategory.wikiCategoryName}
              icon={subCategory.wikiCategoryIcon}
              style={style}
              onClick={() => handleSubCategoryClick(subCategory)}
            >
              {/* Afficher directement les sous-sous-catégories ici */}
              {subCategory.subCategories && (
                <motion.ul
                  className="rounded-b-lg text-black z-10"
                  variants={itemVariants}
                  initial="closed"
                  animate="open"
                >
                  {subCategory.subCategories &&
                    Object.values(subCategory.subCategories).map(
                      (subSubCategory) => (
                        <motion.li
                          key={subSubCategory.wikiCategoryID}
                          className="text-center hover:underline"
                          variants={itemVariants}
                          onClick={() =>navigate(`/subCategory/${subSubCategory.wikiCategoryID}`)}
                        >
                          {subSubCategory.wikiCategoryName}
                        </motion.li>
                      )
                    )}
                </motion.ul>
              )}
            </Squarebtn>
          </div>
        ))}
    </div>
  );
}

export default SubCategoriesList;
