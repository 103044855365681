import { useContext, useEffect, useState } from "react";
import Title1Amatic from "../components/Title1AmaticWhite";
import { useNavigate } from "react-router-dom";
import Squarebtn from "../components/Squarebtn";
import { useTranslation } from "react-i18next";
import NavbarContext from "./Navbar/NavbarContext";
import { useParams } from "react-router-dom";

const HomePage = () => {
  const { navbarData} = useContext(NavbarContext); 
  const navigate = useNavigate();
  const {t, i18n}=useTranslation();
  const { currentLanguage } = useParams();
  const [language,setLanguage]=useState(i18n.language)

  useEffect(() => {
    const languageUrl = i18n.language;
    let newLanguage;
    if (languageUrl === "fr") {
      newLanguage = "fr_FR";
    } else if (languageUrl === "nl") {
      newLanguage = "nl_BE";
    } else {
      newLanguage = "en_EN";
    }
  
    setLanguage(newLanguage);
  
    if (currentLanguage !== newLanguage) {
      navigate(`/${newLanguage}`);
    }
  }, [i18n.language, navigate, currentLanguage]);
  

  useEffect(() => {
    if (currentLanguage && currentLanguage!==language) {
      const languageCode = currentLanguage.split('_')[0];
      i18n.changeLanguage(languageCode);
    }
  }, [currentLanguage, i18n, language]);

  
  const handleCategoryClick = (category) => {
    navigate(`/category/${category.wikiCategoryID}`);
  }; 

  
  return (
    <div className="h-full w-full flex justify-center xxs:flex-col lg:flex-row mt-[10vh] ">
      <div className="max-w-[1535px] xxs:flex-col lg:flex-row justify-between flex w-full">
        
        <div
          style={{ backgroundColor: "#385660"}}
          className="xxs:w-full lg:w-2/5 flex #435B63  text-white justify-center items-center h-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <Title1Amatic
            titleText={t("Welcome")}
            title1Text={t("to Linked.Farm")}
            title2Text={t("wiki")}
          />
        </div>

        <div className="xxs:h-fit xxs:px-0 sm:px-5 xxs:py-3 lg:py-10 lg:h-[80vh] xxs:w-full lg:w-3/5 h-full flex flex-col justify-center relative items-center ">
          <div className="flex xxs:flex-col lg:flex-wrap lg:flex-row justify-center items-center xxs:gap-0 lg:gap-5">
          {Array.isArray(navbarData) && navbarData.map((category) => {
                          const currentCategoryColor =category.color
                          const icon=category.wikiCategoryIcon
              return (
                <Squarebtn
                  key={category.wikiCategoryID}
                  onClick={() => handleCategoryClick(category)}
                  name={category.wikiCategoryName}
                  icon={icon}
                  style={{
                  border:`3px solid ${currentCategoryColor}`,
                  color: currentCategoryColor,
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
