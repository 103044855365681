import React, { useState, useEffect, useRef } from "react";
import Back from "../components/Back";
import ArrowDown from "../components/ArrowDown";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import QuestionDetailsFetcher from "../components/QuestionDetailsFetcher";
import UseMappedLanguage from "../components/UseMappedLanguage";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const SearchPage = () => {
  const [crumbs, setCrumbs] = useState(null);
  console.log(crumbs);
  const mappedLanguage = UseMappedLanguage();
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [selectedPageId, setSelectedPageId] = useState(null);
  const scrollContainerId = "scroll-container";
  const navigate = useNavigate();
  const [results, setResults] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("query");
  const scrollContainerRef = useRef(null);
  const { t, i18n } = useTranslation();
  const { currentLanguage, questionId } = useParams();
  const [language, setLanguage] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const yourQueryString = queryParams.get("query");
  const [hasResults, setHasResults] = useState(true);

  const goBack = () => {
    navigate("/");
  };

  useEffect(() => {
    if (currentLanguage) {
      const languageCode = currentLanguage.split("_")[0];
      i18n.changeLanguage(languageCode);
    }
  }, [currentLanguage, i18n]);

  useEffect(() => {
    if (questionId) {
      const languageUrl = i18n.language;
      if (languageUrl === "fr") {
        const lang = "fr_FR";
        setLanguage("fr_FR");
        navigate(
          `/${lang}/page/${questionId}/search?query=${encodeURIComponent(
            yourQueryString
          )}`
        );
      } else if (languageUrl === "nl") {
        const lang = "nl_BE";
        setLanguage("nl_BE");
        navigate(
          `/${lang}/page/${questionId}/search?query=${encodeURIComponent(
            yourQueryString
          )}`
        );
      } else {
        const lang = "en_EN";
        setLanguage("en_EN");
        navigate(
          `/${lang}/page/${questionId}/search?query=${encodeURIComponent(
            yourQueryString
          )}`
        );
      }
    } else {
      const languageUrl = i18n.language;
      if (languageUrl === "fr") {
        const lang = "fr_FR";
        setLanguage("fr_FR");
        navigate(
          `/${lang}/search?query=${encodeURIComponent(yourQueryString)}`
        );
      } else if (languageUrl === "nl") {
        const lang = "nl_BE";
        setLanguage("nl_BE");
        navigate(
          `/${lang}/search?query=${encodeURIComponent(yourQueryString)}`
        );
      } else {
        const lang = "en_EN";
        setLanguage("en_EN");
        navigate(
          `/${lang}/search?query=${encodeURIComponent(yourQueryString)}`
        );
      }
    }
  }, [i18n.language, language, navigate, questionId, yourQueryString]);

  useEffect(() => {
    if (questionId) {
      setSelectedPageId(questionId);
    }
  }, [questionId, selectedPageId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `https://www.linked.farm/api/v2/wiki/view/wiki.php?data=pagesForSearch&api=wefg496ad45afsjeg4s633924ujimshd4g56a4f13aghg56f4sa&search=${encodeURIComponent(
          query
        )}&lng=${mappedLanguage}`;
        const response = await axios.get(apiUrl);
        if (response.status === 200) {
          const dataArray = Object.values(response.data);
          const sortedDataArray = dataArray.sort(
            (a, b) => b.searchScore - a.searchScore
          );

          setHasResults(true);
          setResults(sortedDataArray);
          setSelectedPageId(sortedDataArray[0].pageID);
          setSelectedQuestion(sortedDataArray[0]);
          console.log(sortedDataArray);
        } else {
          setHasResults(false);
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.error("Error fetching API", error);
        setHasResults(false);
      }
    };
    if (query && mappedLanguage) {
      fetchData();
    }
  }, [query, mappedLanguage, currentLanguage]);

  const handleQuestionClick = (result) => {
    setSelectedQuestion(result);
    setSelectedPageId(result.pageID);
    navigate(
      `/${language}/page/${result.pageID}/search?query=${encodeURIComponent(
        yourQueryString
      )}`
    );
  };

  useEffect(() => {
    if (selectedPageId && questionId && scrollContainerRef.current) {
      setTimeout(() => {
        scrollContainerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);
    }
  }, [selectedPageId, questionId]);

  return (
    <div className="xxs:h-fit xxs:min-h-[90vh] lg:min-h-[80vh] lg:h-[80vh] w-full flex justify-center font-helvetica mt-[10vh]">
      <div className="max-w-[1535px] lg:min-h-[80vh] xxs:h-fit xxs:min-h-[90vh] lg:h-[80vh] xxs:flex-col lg:flex-row justify-between flex w-full">
        <div
          style={{ backgroundColor: "#385660" }}
          className="xxs:w-full lg:w-2/5 xxs:h-fit lg:min-h-[80vh] xxs:min-h-[90vh] lg:h-[80vh] text-white flex flex-col"
        >
          <div className="flex justify-between h-fit w-full xxs:pr-5 lg:pr-10 font-helvetica font-bold uppercase lg:text-[24px] tracking-wider xxs:text-[17px] h-[10vh] items-center">
            <Back onClick={goBack} />
            <p className="font-helvetica flex items-center justify-center h-fit text-center font-semibold uppercase min-h-[10vh] text-[18px]">
              {hasResults
                ? `${results.length} ${t("RESULTS FOR")} ${query}`
                : `0 ${t("RESULTS FOR")} ${query}`}{" "}
            </p>
            <div className="xxs:w-[30px] h-[30px] flex items-center" />
          </div>

          <div className="flex flex-col xxs:h-fit lg;h-[60vh] w-full xxs:overflow-y-hidden text-white lg:overflow-y-scroll justify-start">
            {hasResults ? (
              Object.values(results).map((result) => (
                <div
                  key={result.pageID}
                  className="flex justify-between pt-1 pr-10 pl-2 w-full"
                >
                  <button
                    type="button"
                    className="font-lexendbold flex text-white w-full text-start items-end active:underline focus:underline hover:underline"
                    onClick={() => {
                      handleQuestionClick(result);
                    }}
                  >
                    <p className="pl-10 w-11/12 xxs:text-[18px]  font-bold text-[25px] font-helvetica text-left pt-3">
                      {result.pageName}
                    </p>
                  </button>

                  {questionId && Number(questionId) === result.pageID ? (
                    <FontAwesomeIcon
                      className="pl-2"
                      icon={faArrowRight}
                      style={{ color: "#FFFFFF" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="pl-2"
                      icon={faArrowDown}
                      style={{ color: "#FFFFFF" }}
                    />
                  )}
                </div>
              ))
            ) : (
              <div className="message-erreur"></div>
            )}
          </div>
        </div>

        <div
          className="xxs:h-fit xxs:min-h-[90vh] lg:min-h-[80vh] lg:h-[80vh] xxs:w-full lg:w-3/5 shadow-inner-xl relative flex flex-col items-center xxs:pt-[10vh] lg:pt-0"
          ref={scrollContainerRef}
          style={{ backgroundColor: "#E3E4E7" }}
        >
          {selectedPageId && hasResults && (
            <p
              style={{ color: `#32444B`, backgroundColor: "#E3E4E7" }}
              className="font-helvetica px-10 hyphens-auto flex items-center justify-center h-fit text-center font-semibold uppercase min-h-[10vh] xxs:flex lg:fixed text-[23px]"
            >
              {selectedQuestion?.pageName || "Loading..."}
            </p>
          )}
          <div
            id={scrollContainerId}
            className="xxs:overflow-y-hidden lg:overflow-y-scroll box-border lg:mt-[10vh] xxs:mt-0 xxs:h-fit lg:h-[70vh] relative font-helvetica xxs:px-5 xxs:py-5 xxs:my-5"
            style={{ backgroundColor: "#E3E4E7" }}
          >
            {selectedPageId && hasResults && (
              <QuestionDetailsFetcher
                questionID={selectedPageId}
                mappedLanguage={mappedLanguage}
                setCrumbs={setCrumbs}
              />
            )}
          </div>
          <ArrowDown scrollContainerId={scrollContainerId} />
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
