import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import loupe from "../assets/images/loupe.png";
import { useTranslation } from "react-i18next";

function SearchBar() {
  const { t, i18n } = useTranslation();
  const [keyword, setKeyword] = useState("");
  const currentLanguage = i18n.language;
  const [language, setLanguage] = useState(i18n.language);

  const navigate = useNavigate(); 

    useEffect(() => {
      if (currentLanguage === "fr") {
        setLanguage("fr_FR");
      } else if (currentLanguage === "nl") {
        setLanguage("nl_NL");
      } else {
        setLanguage("en_EN");
      }
    }, [currentLanguage]);
  
    const handleInputChange = (e) => {
      setKeyword(e.target.value);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (keyword.trim()) {
        navigate(`/${language}/search?query=${encodeURIComponent(keyword.trim())}`,
        setKeyword(""));
      }
    };

  return (
    <form onSubmit={handleSubmit} className="  px-1 xxs:w-1/2 min-h-[35px] max-h-[40px] lg:max-w-[300px] lg:w-3/5 flex items-center xxs:justify-center lg:justify-between   box-border   hover:ring-black hover:ring focus:outline-none focus:border-black font-lexend text-base  border-2 focus:border active:border hover:border leading-tight shadow-inner rounded-lg bg-grey">
        <input
          className="xxs:w-2/3 h-[3vh] min-h-[35px] lg:w-full px-1 max-h-[40px] appearance-none focus:bg-transparent active:bg-transparent focus:border-none focus:outline-none  bg-transparent font-helvetica"
          id="name"
          type="text"
          placeholder={t("Search")}
          value={keyword}
          onChange={handleInputChange}
        />
        <button type="submit" className="h-[50px] w-[30px]   pl-2 ">
          <img alt="Search" className="w-full h-full" src={loupe} />
        </button>
    </form>
  );
}

export default SearchBar;
