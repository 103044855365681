import React, { useState, useEffect } from "react";
import axios from "axios";
import QuestionDetails from "../components/QuestionDetails";

function QuestionDetailsFetcher({setCrumbs,mappedLanguage, questionID}) {
  const [questionDetails, setQuestionDetails] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if(questionID){
    const apiUrl = `https://www.linked.farm/api/v2/wiki/view/wiki.php?data=page_v2&api=wefg496ad45afsjeg4s633924ujimshd4g56a4f13aghg56f4sa&pageID=${questionID}&lang=${mappedLanguage}`;

    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        console.log(response)
        setCrumbs(response.data.crumbs)
        setQuestionDetails(response.data.html);
      } catch (error) {
        console.error("Error fetching question details API", error);
        setError(error);
      }
    };

    fetchData();}
  }, [questionID, setCrumbs, mappedLanguage]);

  if (error) {
    return <div>Error loading question details!</div>;
  }

  return questionDetails ? <QuestionDetails details={questionDetails} /> : <div>Please click on a question</div>;
}

export default QuestionDetailsFetcher;
