import { useState, useEffect, useRef } from "react";
import ArrowDown from "../../components/ArrowDown";
import Back from "../../components/Back";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import QuestionDetailsFetcher from "../../components/QuestionDetailsFetcher";
import useMappedLanguage from "../../components/UseMappedLanguage";
import ApiService from "../ApiService";
import NavbarContext from "../Navbar/NavbarContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

function SubCategoryPage() {
  const navigate = useNavigate();
  const { i18n}=useTranslation();

  const { navbarData } = useContext(NavbarContext);
  const [crumbs, setCrumbs] = useState(null);
  const mappedLanguage = useMappedLanguage();
  const { questionId, categoryID } = useParams();
  const [selectedPageId, setSelectedPageId] = useState();
  const [language, setLanguage] = useState(i18n.language);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const scrollContainerRef = useRef(null);
  const scrollContainerRef2 = useRef(null);
  const scrollContainerId = "scroll-container";
  const [subCategory, setSubCategory] = useState([]);
  const [crumb, setCrumb] = useState([]);
  const [category, setCategory] = useState();
  const [idToUse, setIdToUse] = useState(null);
  const { currentLanguage } = useParams();

  useEffect(() => {
    if (currentLanguage ) {
      const languageCode = currentLanguage.split('_')[0];
      i18n.changeLanguage(languageCode);
      if(languageCode!==language){
      setLanguage(currentLanguage)}
    }
  }, [currentLanguage, language, i18n]);

  useEffect(() => {
    const languageUrl = i18n.language;
      let newLanguage;
      if (languageUrl === "fr") {
        newLanguage = "fr_FR";
      } else if (languageUrl === "nl") {
        newLanguage = "nl_BE";
      } else {
        newLanguage = "en_EN";
      }
    if(questionId){
      if (language !== newLanguage) {
        setLanguage(newLanguage);
        navigate(`/page/${questionId}/${newLanguage}`);
      }
    }
  }, [i18n.language, navigate, questionId, currentLanguage,language]);
  
  
  useEffect(() => {
    if (questionId && questions.length === 0) {
      setSelectedPageId(questionId);
      if (crumbs && selectedPageId) {
        const matchingCrumb = crumbs[0];
        setCrumb(matchingCrumb);
        if (matchingCrumb) {
          const newIdToUse =
            matchingCrumb.wikiCategoryGrandParentID === 0
              ? matchingCrumb.wikiCategoryParentID
              : matchingCrumb.wikiCategoryGrandParentID;

          setIdToUse(newIdToUse);
          const newIdQuestions = matchingCrumb.wikiCategoryID;
          if (newIdQuestions) {
            const fetchQuestions = async () => {
              try {
                const data = await ApiService.fetchPagesForCategory(
                  newIdQuestions,
                  mappedLanguage
                );
                console.log(data);
                setQuestions(data);

                if (questions) {
                  const matchingQuestion = data.find(
                    (question) => question.pageID === Number(questionId)
                  );
                  setSelectedQuestion(matchingQuestion);
                }
              } catch (error) {
                console.error("Error fetching questions:", error);
              }
            };
            if (newIdQuestions) {
              fetchQuestions();
            }
          }
        }
      }
    }
  }, [questions, questionId, selectedPageId, crumbs, mappedLanguage]);

  useEffect(() => {
    const fetchQuestions = async () => {
      if (!crumb || !crumb.wikiCategoryID) return;

      try {
        const data = await ApiService.fetchPagesForCategory(
          crumb.wikiCategoryID,
          mappedLanguage
        );
        setQuestions(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, [crumb, mappedLanguage]);

  useEffect(() => {
    if (questionId && questions.length > 0) {
      const matchingQuestion = questions.find(
        (question) => question.pageID === Number(questionId)
      );
      setSelectedQuestion(matchingQuestion);
    }
  }, [questions, questionId]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const data = await ApiService.fetchPagesForCategory(
          categoryID,
          mappedLanguage
        );
        console.log(data);
        setQuestions(data);
        setSelectedQuestion(data[0]);
        setSelectedPageId(data[0].pageID);
        console.log(data[0]);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };
    if (categoryID) {
      fetchQuestions();
    }
  }, [categoryID, mappedLanguage]);

  useEffect(() => {
    if (crumbs && categoryID) {
      const matchingCrumb = crumbs.find(
        (crumb) => crumb.wikiCategoryID === Number(categoryID)
      );
      setCrumb(matchingCrumb);

      if (matchingCrumb) {
        const newIdToUse =
          matchingCrumb.wikiCategoryGrandParentID === 0
            ? matchingCrumb.wikiCategoryParentID
            : matchingCrumb.wikiCategoryGrandParentID;
        setIdToUse(newIdToUse);
      }
    }
  }, [crumbs, categoryID]);

  useEffect(() => {
    if (crumb && category &&category.subCategories) {
      const categoryData = Object.values(category.subCategories);
      categoryData.find(
        (subCategory) => subCategory.wikiCategoryID === crumb.wikiCategoryID
      );
      const subCategoryMatch = categoryData.find(
        (subCat) => subCat.wikiCategoryID === crumb.wikiCategoryID
      );
      if (subCategoryMatch) {
        setSubCategory(subCategoryMatch);
      }
    }
  }, [categoryID, mappedLanguage, idToUse, category, crumb]);

  useEffect(() => {
    if (idToUse) {
      const categorymatch = navbarData.find(
        (cat) => cat.wikiCategoryID === idToUse
      );
      setCategory(categorymatch);
    }
  }, [categoryID, idToUse, navbarData]);

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question);
    setSelectedPageId(question.pageID);
    navigate(`/page/${question.pageID}/${language}`);
    scrollToQuestionDetails();
    scrollToTopOfQuestionDetails();
  };

  useEffect(() => {
    if (questions.length > 0 && selectedPageId) {
      const updatedSelectedQuestion = questions.find(
        (q) => q.pageID === Number(selectedPageId)
      );
      setSelectedQuestion(updatedSelectedQuestion || null);
    }
  }, [questions, selectedPageId]);

  const scrollToQuestionDetails = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const scrollToTopOfQuestionDetails = () => {
    if (scrollContainerRef2.current) {
        scrollContainerRef2.current.scrollTop = 0;
    }
};

  useEffect(() => {
    if (selectedPageId && questionId &&scrollContainerRef.current) {
      setTimeout(() => {scrollContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start",});}, 100); 
    }
  }, [selectedPageId, questionId]);

  const currentCategoryColor = category ? category.color : "#385660";

  return (
    <div className="xxs:h-fit xxs:min-h-[90vh] lg:min-h-[80vh] lg:h-[80vh] w-full flex justify-center font-helvetica mt-[10vh]">
      <div className="max-w-[1535px] lg:min-h-[80vh] xxs:h-fit xxs:min-h-[90vh] lg:h-[80vh] xxs:flex-col lg:flex-row justify-between flex w-full">
        
        {/* Category Section */}
        <div
          style={{ backgroundColor: currentCategoryColor }}
          className="xxs:w-full lg:w-2/5 xxs:h-fit lg:min-h-[80vh] xxs:min-h-[90vh] lg:h-[80vh] text-white flex flex-col"
        >
          <div className="flex justify-between h-fit w-full xxs:pr-5 lg:pr-10 font-helvetica font-bold uppercase lg:text-[24px] tracking-wider xxs:text-[17px] h-[10vh] items-center">
          <Back onClick={() => navigate(`/category/${category.wikiCategoryID}`)} />
            {subCategory ? (
              <p className="font-helvetica flex items-center justify-center h-fit text-center font-semibold uppercase min-h-[10vh] text-[18px]">
                {subCategory.wikiCategoryName}
              </p>
            ) : (
              <p>Loading...</p>
            )}
            <div className="xxs:w-[30px] h-[30px] flex items-center" />
          </div>

          {/* Questions List */}
          <div className="flex flex-col xxs:h-fit lg;h-[60vh] w-full xxs:overflow-y-hidden text-white lg:overflow-y-scroll justify-start">
            {questions.map((question) => (
              <button
                key={question.pageID}
                type="button"
                className="font-helvetica flex text-white w-full text-start items-end active:underline focus:underline hover:underline"
                onClick={() => {
                  handleQuestionClick(question);
                  scrollToQuestionDetails();
                }}
              >
                <p className="pl-10 w-11/12 xxs:text-[18px]  font-bold text-[25px] font-helvetica text-left pt-3">
                  {question.pageName}
                </p>
                <FontAwesomeIcon
                  className="pl-2 pb-1 xxs:text-black lg:text-white"
                  icon={
                    selectedPageId === question.pageID
                      ? faArrowRight
                      : faArrowDown
                  }
                  style={{ color: "#FFFFFF" }}
                />
              </button>
            ))}
          </div>
        </div>

        {/* Question Details Section */}
        <div
          style={{ backgroundColor: "#E3E4E7" }}
          ref={scrollContainerRef}
          className="xxs:h-fit xxs:min-h-[90vh] lg:min-h-[80vh] lg:h-[80vh] xxs:w-full lg:w-3/5 shadow-inner-xl relative flex flex-col items-center xxs:pt-[10vh] lg:pt-0"
        >
          {selectedPageId && (
            <p
              style={{ color: `#32444B` }}
              className="font-helvetica px-10 hyphens-auto flex items-center justify-center h-fit text-center font-semibold uppercase min-h-[10vh] xxs:flex lg:fixed text-[23px]"
            >
              {selectedQuestion?.pageName || "Loading..."}
            </p>
          )}
          <div
            style={{ color: `#32444B` }}
            id={scrollContainerId}
            ref={scrollContainerRef2}
            className="xxs:overflow-y-hidden lg:overflow-y-scroll box-border lg:mt-[10vh] xxs:mt-0 xxs:h-fit lg:h-[70vh] relative font-helvetica xxs:px-5 xxs:py-5 xxs:my-5"
          >
            <QuestionDetailsFetcher
              setCrumbs={setCrumbs}
              questionID={selectedPageId}
              mappedLanguage={mappedLanguage}
            />
          </div>
          <ArrowDown scrollContainerId={scrollContainerId} />
        </div>
      </div>
    </div>
  );
}

export default SubCategoryPage;
