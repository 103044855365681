import React, { useState, useEffect, useMemo } from 'react';
import NavbarContext from "./NavbarContext";
import axios from "axios";
import UseMappedLanguage from '../../components/UseMappedLanguage';

export const NavbarProvider = ({ children }) => {
  const [navbarData, setNavbarData] = useState([]);
  const mappedLanguage=UseMappedLanguage

  const categoryColors = useMemo(
    () => ({
      yellow: "#366B75",
      blue: "#165881",
      green: "#2D604D",
      brown: "#32444B",
    }),
    []
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const mainCategoriesUrl = `https://www.linked.farm/api/v2/wiki/view/wiki.php?data=categoriesForParentID&api=wefg496ad45afsjeg4s633924ujimshd4g56a4f13aghg56f4sa&parentID=0&lang=${mappedLanguage}`;
        const mainCategoriesResponse = await axios.get(mainCategoriesUrl);

        if (mainCategoriesResponse.status === 200) {
          const categoriesWithColors = mainCategoriesResponse.data.map(
            (category, index) => {
              const colorIndex = index % Object.keys(categoryColors).length;
              const colorKey = Object.keys(categoryColors)[colorIndex];
              return { ...category, color: categoryColors[colorKey] };
            }
          );
          setNavbarData(categoriesWithColors);
        } else {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des catégories:", error);
      }
    }

    fetchData();
  }, [categoryColors, mappedLanguage]); 

  if (!navbarData || navbarData.length === 0) {
    return <div>Loading...</div>; 
  }

  return (
    <NavbarContext.Provider value={{ navbarData, setNavbarData }}>
      {children}
    </NavbarContext.Provider>
  );
};
