import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Title1Amatic from "../components/Title1AmaticWhite";
import SubCategoriesList from "./SubCategoriesList";
import useMappedLanguage from "../components/UseMappedLanguage";
import NavbarContext from "./Navbar/NavbarContext";
import { useContext } from "react";
import Back from "../components/Back";
import { useNavigate } from "react-router-dom";

function CategoryPage() {
  const { categoryID } = useParams();
  const mappedLanguage = useMappedLanguage();
  const [category, setCategory] = useState();
  const { navbarData } = useContext(NavbarContext);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate()

  useEffect(() => {
    if (Array.isArray(navbarData) && navbarData.length > 0) {
      const categoryMatch = navbarData.find((category) => {
        return category.wikiCategoryID === Number(categoryID);
      });
      if (categoryMatch) {
        setCategory(categoryMatch);
      } else {
        console.log("No matching category found for ID:", categoryID);
      }
    } else {
      console.error("navbarData is not an array or is empty:", navbarData);
    }
  }, [categoryID, navbarData]);

  useEffect(() => {
    if (Array.isArray(navbarData) && navbarData.length > 0) {
      setIsLoading(false); 
    } else {
      console.error("navbarData is not an array or is empty:", navbarData);
  
    }
  }, [categoryID, navbarData]);

  if (isLoading) {
    return <div>Loading...</div>; 
  }
  

  if (!navbarData || navbarData.length === 0) {
    return <div>Loading...</div>;
  }
  

  if (!category) {
    return (
      <div className="w-full h-[80vh] flex text-black">
        Category not found
      </div>
    );
  }

  const currentCategoryColor = category ? category.color : "#385660";

  return (
    <div className="xxs:h-fit lg:h-[80vh] w-full flex items-center mt-[10vh] justify-center text-black ">
      <div className="max-w-[1535px] justify-between xxs:flex-col lg:flex-row  flex w-full xxs:h-fit lg:h-[80vh]">
        <div
          className={`xxs:w-full lg:w-2/5  xxs:h-[10vh] lg:h-[80vh] flex xxs:flex-row lg:flex-col  justify-between `}
          style={{ backgroundColor: currentCategoryColor }}
        >
          <Back onClick={() => navigate(`/`)} />
          <div className="flex w-full items-center xxs:h-fit  mb-0 text-white  justify-center">
            <Title1Amatic
              titleText={category.wikiCategoryName}
              icon={category.wikiCategoryIcon}
            />
          </div>
          <div className=" w-[30px] pr-5 h-[10vh] flex items-center" />
        </div>

        <div className="xxs:w-full lg:w-3/5 xxs:h-fit lg:h-full flex justify-center relative items-center xxs:p-0 xxs:py-5 lg:px-10">
          {category && (
            <SubCategoriesList
              category={category}
              style={{
                border: `3px solid ${currentCategoryColor}`,
                color: currentCategoryColor,
                mappedLanguage: mappedLanguage,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CategoryPage;
