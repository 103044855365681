import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import en from "../locales/en/en.json";
import nl from "../locales/nl/nl.json";
import fr from "../locales/fr/fr.json";
const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  nl: {
    translation: nl,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    fallbackLang: "en",
  });

export default i18n;
