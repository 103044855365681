
import axios from "axios";
const ApiService = {

  async fetchMenu( language) {
    const apiUrl = `https://www.linked.farm/api/v2/wiki/view/wiki.php?data=wikiCategories&api=wefg496ad45afsjeg4s633924ujimshd4g56a4f13aghg56f4sa&lang=${language}`;
    try {
      const response = await axios.get(apiUrl);
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la récupération du menu:", error);
      throw error;
    }
  },


  async fetchCategories( language) {
    const apiUrl = `https://www.linked.farm/api/v2/wiki/view/wiki.php?data=categoriesForParentID&api=wefg496ad45afsjeg4s633924ujimshd4g56a4f13aghg56f4sa&parentID=0&lang=${language}`;
    try {
      const response = await axios.get(apiUrl);
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la récupération des catégories:", error);
      throw error;
    }
  },

  async fetchSubCategories(categoryID, language) {
    const apiUrl = `https://www.linked.farm/api/v2/wiki/view/wiki.php?data=categoriesForParentID&api=wefg496ad45afsjeg4s633924ujimshd4g56a4f13aghg56f4sa&parentID=${categoryID}&lang=${language}`;
    try {
      const response = await axios.get(apiUrl);
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la récupération des sous-catégories:", error);
      throw error;
    }
  },

  async fetchPagesForCategory(categoryID,mappedLanguage) {
    const apiUrl = `https://www.linked.farm/api/v2/wiki/view/wiki.php?data=pagesForCategoryID&api=wefg496ad45afsjeg4s633924ujimshd4g56a4f13aghg56f4sa&categoryID=${categoryID}&lang=${mappedLanguage}`;
    try {
      const response = await axios.get(apiUrl);
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la récupération des pages:", error);
      throw error;
    }
  },
  async fetchQuestionDetails(questionId, mappedLanguage) {
    const apiUrl = `https://www.linked.farm/api/v2/wiki/view/wiki.php?data=questionDetails&api=wefg496ad45afsjeg4s633924ujimshd4g56a4f13aghg56f4sa&questionID=${questionId}&lang=${mappedLanguage}`;
    try {
      const response = await axios.get(apiUrl);
      return response.data;
    } catch (error) {
      console.error("Error fetching question details:", error);
      throw error;
    }
  },
  // Ajoutez d'autres méthodes API ici si nécessaire
};

export default ApiService;
