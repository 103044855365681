// hooks/useMappedLanguage.js
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function UseMappedLanguage() {
  const { i18n } = useTranslation();

  const mappedLanguage = useMemo(() => {
    switch (i18n.language) {
      case "en": return "en_US";
      case "nl": return "nl_BE";
      case "fr": return "fr_FR";
      default: return "en_US";
    }
  }, [i18n.language]);

  return mappedLanguage;
}

export default UseMappedLanguage;
