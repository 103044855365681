import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { GlobeAltIcon } from "@heroicons/react/24/outline";

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: {
    opacity: 0,
    y: 20,
    transition: { duration: 0 },
  },
};

const Language = () => {
  const [isOpen, setIsOpen] = useState(false);
  const languageRef = useRef(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    function handleClickOutside(event) {
      if (languageRef.current && !languageRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const changeLanguage = (lang) => {
    setIsOpen(false);
    i18n.changeLanguage(lang);
    localStorage.setItem("preferredLanguage", lang);
  };

  return (
    <motion.li
      ref={languageRef}
      initial={false}
      animate={isOpen ? "open" : "closed"}
      className="w-fit text-black flex relative font-lexendbold "
    >
      <motion.div
        className="arrow text-black  hover:text-black uppercase"
        whileTap={{ scale: 0.97 }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <GlobeAltIcon className="xxs:w-5 xxs:h-5 lg:w-5 lg:h-5  text-black xxs:text-base lg:text-s "/>
       <p className=" text-sm "> {i18n.language}</p>
        <motion.div
          variants={{
            open: { rotate: 180 },
            closed: { rotate: 0 },
          }}
          transition={{ duration: 0.1 }}
          style={{ originY: 0.55 }}
        >
          <ChevronDownIcon className="w-6  h-9 stroke-4  "/>
        </motion.div>
      </motion.div>
      <motion.ul
        className="languages absolute  mt-[5px] mr-[14px] xxs:text-base lg:text-s
         xs:rounded-b-3xl xxs:rounded-l-3xl xs:rounded-l-3xl xxs:rounded-b-3xl                              
         xxs:top-9 lg:top-8   border-2 right-2 sm:mr-15 p-3 z-[10] text-right"
        variants={{
          open: {
            border:"1px solid black",
            backgroundColor: "white",
            color: "black",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.5,
              delayChildren: 0.1,
              staggerChildren: 0.02,
            },
          },
          closed: {
            backgroundColor: "transparent",
            boxShadow: "none",
            border:"none",
            transition: {
              duration: 0,
            },
          },
        }}
        style={{ pointerEvents: isOpen ? "auto" : "none" }}
      >
        <motion.li
          className="language  xxs:text-base lg:text-s cursor-pointer tracking-wider text-black whitespace-nowrap"
          onClick={() => changeLanguage("en")}
          variants={itemVariants}
        >
          EN{" "}
        </motion.li>
        <motion.li
          className="language  xxs:text-base lg:text-s cursor-pointer tracking-wider text-black whitespace-nowrap"
          onClick={() => changeLanguage("fr")}
          variants={itemVariants}
        >
          FR
        </motion.li>
        <motion.li
          className="language xxs:text-base lg:text-s tracking-wider cursor-pointer text-black whitespace-nowrap"
          onClick={() => changeLanguage("nl")}
          variants={itemVariants}
        >
          NL
        </motion.li>
      </motion.ul>
    </motion.li>
  );
};

export default Language;
