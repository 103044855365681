import { useRef} from "react";
import { motion } from "framer-motion";

export default function Menu({ isOpen}) {
  const menuRef = useRef(null);

  return (
    <motion.div ref={menuRef}
                initial={false}
                className=" uppercaset text-black xxs:hidden lg:flex text-right"
    >
      <motion.div className=" cursor-pointer h-fit  flex items-center py-1 px-1 border rounded-md text-base">
        {"MENU"}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-[2vh] h-[2vh]"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </motion.div>
    </motion.div>
  );
}
