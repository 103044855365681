import React from 'react';

const VideoViewer = ({ videoUrl, title = 'Embedded Video', width = '580', height = '320' }) => {

  return (
    <div className="video-container" style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
      <iframe
        src={videoUrl}
        title={title}
        width={width}
        height={height}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: '0' 
        }}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideoViewer;
