import React from 'react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import ImageViewer from './RenderBlock';
import VideoViewer from './VideoViewer'; 

function QuestionDetails({ details, scrollContainerId }) {
  if (!details) return null;

  // Configure DOMPurify to allow iframes and the allowfullscreen attribute
  DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    // set all elements owning target to open in new tab
    if ('target' in node) {
      node.setAttribute('target', '_blank');
    }
    // set non-HTML/MathML links to xlink:show=new
    if (!node.hasAttribute('target') && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))) {
      node.setAttribute('xlink:show', 'new');
    }
  });

  const sanitizedDetails = DOMPurify.sanitize(details, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allowfullscreen'],
  });

  // Replace img tags with ImageViewer components and iframe tags with VideoViewer components
  const options = {
    replace: domNode => {
      if (domNode.name === 'img' && domNode.attribs && domNode.attribs.src) {
        const baseImageUrl = "https://www.linked.farm";
        const imageUrl = `${baseImageUrl}${domNode.attribs.src}`;
        return <ImageViewer imageUrl={imageUrl} />;
      } else if (domNode.name === 'iframe' && domNode.attribs && domNode.attribs.src) {
        const videoUrl = domNode.attribs.src;
        return <VideoViewer videoUrl={videoUrl} title="Embedded YouTube Video" />;
      }
    }
  };

  const parsedContent = parse(sanitizedDetails, options);

  return (
    <div  className=" question-details overflow-hidden ">
      {parsedContent}
    </div>
  );
}

export default QuestionDetails;
