import React, { useState } from 'react';
import Modal from 'react-modal';
import { useSpring, animated } from 'react-spring';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";

const ImageViewer = ({ imageUrl }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const animation = useSpring({
      opacity: modalIsOpen ? 1 : 0,
      transform: modalIsOpen ? `translateY(0)` : `translateY(-100%)`
  });

  const openModal = () => {
      setModalIsOpen(true);
  };

  const closeModal = () => {
      setModalIsOpen(false);
  };

  return (

    <div className='w-full h-full items-center flex justify-center relative '> {/* Add container styles here if needed */}
     <div className='xxs:w-full lg:w-2/3 relative'>
          <img 
              className='w-full  drop-shadow-s border-2 border-white mb-2' 
              src={imageUrl} 
              alt="" 
              onClick={openModal}
          />
  <FontAwesomeIcon onClick={openModal} style={{  zIndex:10}} className='text-green absolute z-10 right-1 top-1 bg-white' size='xs' icon={faUpRightAndDownLeftFromCenter} />
  </div>
          <Modal 
           className=" xxs:mx-5  lg:mx-72 rounded-md   flex justify-center items-center h-full mt-[15vh]  "
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={{ 
                  overlay: {
                      backgroundColor: 'rgba(0, 0, 0, 0.5)'
                  },
                  content: {
                      color: 'lightsteelblue'
                  },
                  
              }}
              contentLabel="Enlarged Image"
          >
              <animated.div style={animation} className=" ">
                  <img src={imageUrl} alt="" style={{ width: '100%', height:"100%" }} />
              </animated.div>
          </Modal>
     
    </div>

  );
};



export default ImageViewer;
