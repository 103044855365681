import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser,faTractor,faPeopleRoof,faQuestion,faBasketShopping,faCarrot,faBoxesStacked,faPiggyBank,faBuilding,faChartSimple,faPeopleArrows,faGears,faCheck} from '@fortawesome/free-solid-svg-icons';

const Title1AmaticWhite = ({ titleText, title1Text, title2Text, icon }) => {
  const iconMap = {
    faUser,
    faTractor,
    faPeopleRoof,
    faQuestion,
    faBasketShopping,
    faCarrot,
    faBoxesStacked,
    faPiggyBank,
    faBuilding,
    faChartSimple,
    faPeopleArrows,
    faGears,
    faCheck
  };
  function extractIconName(iconString) {
    if (!iconString) {
      return null; // Retourne null si iconString est undefined ou null
    }
    const match = iconString.match(/icon=\{(fa[a-zA-Z]+)\}/);
    return match ? match[1] : null;
  }
  
  const iconName = extractIconName(icon);
  const faIcon = iconMap[iconName]; // Récupère l'objet d'icône correspondant

  return (
    <div className="flex xxs:flex-row text-white lg:flex-col  justify-center items-center xxs:h-fit  xxs:min-h-[10vh]  uppercase  font-helvetica font-bold">
      <h1
        className="  xl:text-[40px] lg:text-[34px]  xxs:text-[17px] text-center leading-relaxed tracking-wider hyphens-auto  mb-0 p-O"
      >
        {titleText} <br className="xxs:hidden lg:block"/> {title1Text} <br className="xxs:hidden  hyphens-auto  lg:block"/> {title2Text} 
      </h1>
      {faIcon && <FontAwesomeIcon icon={faIcon} className="xxs:hidden lg:block lg:w-[150px] h-[150px]" />}
    </div>
  );
};

export default Title1AmaticWhite;
